import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import logoImg from '../assets/Logo/Canto_Catch-logo_full.png'

const LogoImg = styled.img`
  z-index: 9999;
  position: relative;
  height: 200px; 
  margin-top: 125px;
  transition: all 0.2s ease;
  &:hover {
    transform: scale(1.1);
  }
`

const Logo = () => {
  return (
    <Link to="/">
      <LogoImg src={logoImg} alt="Canto Catch" />
    </Link>
  )
}

export default Logo



// import React from 'react'
// import { Link } from 'react-router-dom'
// import styled from 'styled-components'

// const LogoText = styled.h1`
// font-family: 'Akaya Telivigala', cursive;
// font-size: ${props => props.theme.fontxl};
// color: ${props => props.theme.text};
// transition: all 0.2s ease;

// &:hover{
//     transform: scale(1.1);
// }

// @media (max-width: 64em){
// font-size: ${props => props.theme.fontxl};

// }
// `

// const Logo = () => {
//   return (
//     <LogoText>
//         <Link to="/">
//         Canto Catch
//         </Link>
//     </LogoText>
//   )
// }

// export default Logo